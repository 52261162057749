import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { PRODUCT_PRICE } from '../../utils/constants/product-price.constants';
import { baseQueryWithReauth } from '../auth/auth.api';

export const productPriceApi = createApi({
    reducerPath: 'productPriceApi',
    tagTypes: ['productPrice'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllProductPrices: builder.query<
            [],
            { page?: number; limit: number, search_key?: string, product_id?: string, product_category_id?: string }
        >({
            query: ({ page = 1, limit = 10, search_key, product_category_id, product_id }) => {
                const queryParams = {
                    url: PRODUCT_PRICE.GET_ALL,
                    params: { page, limit, status: 1, search_key, product_category_id, product_id },
                };

                if (search_key) {
                    queryParams.params.search_key = search_key;
                }

                if (product_category_id) {
                    queryParams.params.product_category_id = product_category_id;
                }

                return queryParams;
            },
        }),
        getProductPrice: builder.query<any, { id: string | number }>({
            query: ({ id }) => PRODUCT_PRICE.EDIT + id,
        }),
        createProductPrice: builder.mutation({
            query: (body) => ({
                url: PRODUCT_PRICE.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateProductPrice: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRODUCT_PRICE.UPDATE}${id}`,
                method: 'PUT',
                body,
            }),
        }),
        deleteProductPrice: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_PRICE.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0
});

export const {
    useLazyGetAllProductPricesQuery,
    useGetProductPriceQuery,
    useCreateProductPriceMutation,
    useUpdateProductPriceMutation,
    useDeleteProductPriceMutation,
    useGetAllProductPricesQuery
} = productPriceApi;
