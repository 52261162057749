export const PRODUCT_PRICE = {
    BASE_PATH: '/product/product-prices/',
    GET_ALL: '/product-prices',
    CREATE: '/product-prices',
    EDIT: '/product-prices/',
    UPDATE: '/product-prices/',
    DELETE: '/product-prices/',
    ADD_NEW_TITLE: 'Add New Unit of Conversion',
    GET_BASE_UNIT: '/product-prices/get/',
};
