export const PRODUCT_UNIT_OF_CONVERSION = {
    BASE_PATH: '/product/product-unit-of-conversions/',
    GET_ALL: '/product-unit-conversion',
    CREATE: '/product-unit-conversion',
    EDIT: '/product-unit-conversion/',
    UPDATE: '/product-unit-conversion/',
    DELETE: '/product-unit-conversion/',
    ADD_NEW_TITLE: 'Add New Unit of Conversion',
    GET_BASE_UNIT: '/product-unit-conversion/get/',
};
