import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getBaseUrl } from '../../utils/general/getBaseUrl';
import { PRODUCT_UNIT_OF_CONVERSION } from '../../utils/constants/product-uoc.constant';
import { baseQueryWithReauth } from '../auth/auth.api';

export const productUnitOfConversionApi = createApi({
    reducerPath: 'productUnitOfConversionApi',
    tagTypes: ['ProductUnitOfConversion'],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllProductUnitOfConversions: builder.query<
            [],
            { page?: number; limit: number, search_key?: string, product_id?: string }
        >({
            query: ({ page = 1, limit = 10, search_key, product_id }) => {
                const queryParams = {
                    url: PRODUCT_UNIT_OF_CONVERSION.GET_ALL,
                    params: { page, limit, status: 1, search_key, product_id },
                };

                if (search_key) {
                    queryParams.params.search_key = search_key;
                }

                if (product_id) {
                    queryParams.params.product_id = product_id;
                }

                return queryParams;
            },
        }),
        getProductUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => PRODUCT_UNIT_OF_CONVERSION.EDIT + id,
        }),

        getProductBaseUnitOfConversion: builder.query<any, { id: string | number }>({
            query: ({ id }) => PRODUCT_UNIT_OF_CONVERSION.GET_BASE_UNIT + id,
        }),
        createProductUnitOfConversion: builder.mutation({
            query: (body) => ({
                url: PRODUCT_UNIT_OF_CONVERSION.CREATE,
                method: 'POST',
                body,
            }),
        }),
        updateProductUnitOfConversion: builder.mutation({
            query: ({ id, body }) => ({
                url: `${PRODUCT_UNIT_OF_CONVERSION.UPDATE}${id}`,
                method: 'PATCH',
                body,
            }),
        }),
        deleteProductUnitOfConversion: builder.mutation({
            query: (id) => ({
                url: `${PRODUCT_UNIT_OF_CONVERSION.DELETE}${id}`,
                method: 'DELETE',
            }),
        }),
    }),
    keepUnusedDataFor: 0
});

export const {
    useGetAllProductUnitOfConversionsQuery,
    useLazyGetAllProductUnitOfConversionsQuery,
    useCreateProductUnitOfConversionMutation,
    useGetProductUnitOfConversionQuery,
    useLazyGetProductUnitOfConversionQuery,
    useLazyGetProductBaseUnitOfConversionQuery,
    useUpdateProductUnitOfConversionMutation,
    useDeleteProductUnitOfConversionMutation,
} = productUnitOfConversionApi;
