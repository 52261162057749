import CustomerIcon from "@components/icons/menu/CustomerIcon";
import DirectSalesReturnIcon from "@components/icons/menu/DirectSalesReturnIcon";
import PrimarySalesOrderIcon from "@components/icons/menu/PrimarySalesOrderIcon";
import PrimarySalesReturnIcon from "@components/icons/menu/PrimarySalesReturnIcon";
import ProductIcon from "@components/icons/menu/ProductIcon";
import PurchaseIcon from "@components/icons/menu/PurchaseIcon";
import UnitIcon from "@components/icons/menu/UnitIcon";
import UserManagementIcon from "@components/icons/menu/UserManagementIcon";
import WarehouseAndInventoryIcon from "@components/icons/menu/WarehouseAndInventoryIcon";

import { modules, subModules, moduleMatchIds, subModulesMatchIds } from "@config/app-modules";
import { 
    FaShoppingCart,
    FaUsers,
    FaBalanceScale,
    FaBox,
    FaWarehouse,
    FaUser,
    FaCreditCard,
    FaUndo,
    FaCashRegister,
    FaTruck,
    FaBoxOpen,
    FaFileAlt,

    FaStore,
    FaTag,
    FaUserShield,
    FaStoreSlash,
    FaUserCog,
    FaExchangeAlt,
    FaRuler,
    FaBoxTissue,
    FaTrademark,
    FaTags,
    FaFileImport,
    FaBalanceScaleLeft,
    FaPencilAlt,
    FaUserFriends,
    FaTruckLoading,
    FaClipboardCheck,
    FaFileInvoiceDollar,
    FaMoneyCheckAlt,
    FaUserCheck,
    FaClipboardList,
    FaChartLine,
    FaMoneyBillWave,
    FaShoppingBag,
    FaHistory,
    FaBoxes,
    FaUndoAlt,
    FaChartBar,
    FaRegMoneyBillAlt
} from 'react-icons/fa';

const mainModules = [
    {
        id: 1,
        match_id: moduleMatchIds[modules.SHOP_MANAGEMENT],
        name: "Shop Management",
        path: "/shop-management",
        icon: FaShoppingCart,
        subModules: [
            {
                id: 211,
                match_id: subModulesMatchIds[subModules.SHOP_CATEGORY],
                name: "Shop Category",
                path: "/shop-category",
                icon: FaTag
            },
            {
                id: 222,
                match_id: subModulesMatchIds[subModules.SHOP],
                name: "Shop",
                path: "/shop",
                icon: FaStore,
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <UserManagementIcon isRouted={isRouted} />;
        },
    },
    {
        id: 2,
        match_id: moduleMatchIds[modules.USER_MANAGEMENT],
        name: "User Management",
        path: "/user-management",
        icon: FaUsers,
        subModules: [
            {
                id: 11,
                match_id: subModulesMatchIds[subModules.USER],
                name: "User",
                path: "/office-user",
                icon: FaUser,
            },
            {
                id: 1111,
                match_id: subModulesMatchIds[subModules.USER_ROLE],
                name: "User Role",
                path: "/user-roles",
                icon: FaUserShield
            },
            {
                id: 12,
                match_id: subModulesMatchIds[subModules.SHOP_USER_ASSIGN],
                name: "Shop User Assign",
                path: "/shop-user-assign",
                icon: FaUserCog
            },
            {
                id: 13,
                match_id: subModulesMatchIds[subModules.USER_ROLE_ASSIGN],
                name: "User Role Assign",
                path: "/user-role-assign",
                icon: FaUserCog
            }
        ],
        renderIcon: (isRouted: boolean) => {
            return <UserManagementIcon isRouted={isRouted} />;
        },
    },
    {
        id: 3,
        match_id: moduleMatchIds[modules.UNIT],
        name: "Unit",
        path: "/unit",
        icon: FaBalanceScale,
        subModules: [
            {
                id: 31,
                match_id: subModulesMatchIds[subModules.UNIT_OF_CONVERSION],
                name: "Unit of Conversion",
                path: "/unit-of-conversion",
                icon: FaExchangeAlt
            },
            {
                id: 32,
                match_id: subModulesMatchIds[subModules.UNIT_OF_MEASUREMENT],
                name: "Unit of Measurement",
                path: "/unit-of-measurement",
                icon: FaRuler
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <UnitIcon isRouted={isRouted} />;
        },
    },
    {
        id: 4,
        match_id: moduleMatchIds[modules.PRODUCT],
        name: "Product",
        path: "/product",
        icon: FaBox,
        subModules: [
            {
                id: 21,
                match_id: subModulesMatchIds[subModules.PRODUCT_INFORMATION],
                name: "Product Information",
                path: "/product-information",
                icon: FaBoxOpen
            },
            {
                id: 24,
                match_id: subModulesMatchIds[subModules.BRAND],
                name: "Brand",
                path: "/brand",
                icon: FaTrademark
            },
            {
                id: 22,
                match_id: subModulesMatchIds[subModules.PRODUCT_CATEGORY],
                name: "Product Category",
                path: "/product-category",
                icon: FaTags
            },
            {
                id: 23,
                match_id: subModulesMatchIds[subModules.PRODUCT_CATEGORY_ASSIGN],
                name: "Product Category Assign",
                path: "/product-category-assign",
                icon: FaTag
            },
            {
                id: 1003,
                match_id: subModulesMatchIds[subModules.PRODUCT_UOC],
                name: "Product UOC (Unit of Conversion)",
                path: "/product-unit-of-conversions",
                icon: FaExchangeAlt
            },
            {
                id: 1004,
                match_id: subModulesMatchIds[subModules.PRODUCT_PRICE],
                name: "Product Price",
                path: "/product-prices",
                icon: FaRegMoneyBillAlt
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <ProductIcon isRouted={isRouted} />;
        },
    },
    {
        id: 5,
        match_id: moduleMatchIds[modules.WAREHOUSE_INVENTORY],
        name: "Warehouse & Inventory",
        path: "/warehouse-and-inventory",
        icon: FaWarehouse,
        subModules: [
            {
                id: 61,
                match_id: subModulesMatchIds[subModules.WAREHOUSE],
                name: "Warehouse",
                path: "/warehouse",
                icon: FaWarehouse,
            },
            {
                id: 63,
                match_id: subModulesMatchIds[subModules.STOCK_IMPORT],
                name: "Stock Import",
                path: "/stock-import",
                icon: FaFileImport
            },
            {
                id: 64,
                match_id: subModulesMatchIds[subModules.STOCK_TRANSFER],
                name: "Stock Transfer",
                path: "/stock-transfer",
                icon: FaTruck
            },
            {
                id: 65,
                match_id: subModulesMatchIds[subModules.STOCK_BALANCE],
                name: "Stock Balance",
                path: "/stock-balance",
                icon: FaChartLine
            },
            {
                id: 66,
                match_id: subModulesMatchIds[subModules.STOCK_ADJUSTMENT],
                name: "Stock Adjustment",
                path: "/stock-adjustment",
                icon: FaPencilAlt
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <WarehouseAndInventoryIcon isRouted={isRouted} />;
        },
    },
    {
        id: 6,
        match_id: moduleMatchIds[modules.CUSTOMER],
        name: "Customer",
        path: "/customer",
        icon: FaUser,
        subModules: [
            {
                id: 41,
                match_id: subModulesMatchIds[subModules.CUSTOMER_CATEGORY],
                name: "Customer Category",
                path: "/customer-category",
                icon: FaUserCheck
            },
            {
                id: 42,
                match_id: subModulesMatchIds[subModules.CUSTOMER],
                name: "Customer",
                path: "/customer",
                icon: FaUserFriends
            }
        ],
        renderIcon: (isRouted: boolean) => {
            return <CustomerIcon isRouted={isRouted} />;
        },
    },
    {
        id: 7,
        match_id: moduleMatchIds[modules.PURCHASE],
        name: "Purchase",
        path: "/purchase",
        icon: FaCreditCard,
        subModules: [
            {
                id: 101,
                match_id: subModulesMatchIds[subModules.SUPPLIER],
                name: "Supplier",
                path: "/supplier",
                icon: FaUserCheck
            },
            {
                id: 123,
                match_id: subModulesMatchIds[subModules.PURCHASE_ORDER],
                name: "Purchase Order",
                path: "/purchase-order",
                icon: FaClipboardList,
            },
            {
                id: 141,
                match_id: subModulesMatchIds[subModules.BILL],
                name: "Bill",
                path: "/bill",
                icon: FaFileInvoiceDollar
            },
            {
                id: 142,
                match_id: subModulesMatchIds[subModules.PAYMENT_MADE],
                name: "Payment Made",
                path: "/payment-made",
                icon: FaMoneyCheckAlt
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PurchaseIcon isRouted={isRouted} />;
        },
    },
    {
        id: 8,
        match_id: moduleMatchIds[modules.PURCHASE_RETURN],
        name: "Purchase Return",
        path: "/purchase-return",
        icon: FaUndo,
        subModules: [
            {
                id: 231,
                match_id: subModulesMatchIds[subModules.PURCHASE_RETURN],
                name: "Purchase Return",
                path: "/purchase-return",
                icon: FaUndo,
            },
            {
                id: 232,
                match_id: subModulesMatchIds[subModules.PURCHASE_RETURN_PAYMENT_RECEIVE],
                name: "Payment Receive",
                path: "/payment-receive",
                icon: FaMoneyBillWave
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesReturnIcon isRouted={isRouted} />;
        },
    },
    {
        id: 9,
        match_id: moduleMatchIds[modules.RETAIL_SALE],
        name: "Retail Sale",
        path: "/retail-sale",
        icon: FaCashRegister,
        subModules: [
            {
                id: 231,
                match_id: subModulesMatchIds[subModules.RETAIL_SALE_ORDER],
                name: "New Retail Sale",
                path: "/retail-sale/add",
                icon: FaShoppingBag,
            },
            {
                id: 232,
                match_id: subModulesMatchIds[subModules.RETAIL_SALE_ORDER_HISTORY],
                name: "Retail Sale Order History",
                path: "/retail-sale-order-history",
                icon: FaHistory,
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesReturnIcon isRouted={isRouted} />;
        },
    },
    {
        id: 10,
        match_id: moduleMatchIds[modules.WHOLE_SALE],
        name: "Whole Sale",
        path: "/whole-sale-order",
        icon: FaTruck,
        subModules: [
            {
                id: 71,
                match_id: subModulesMatchIds[subModules.WHOLE_SALE],
                name: "Wholesale",
                path: "/sale-order",
                icon: FaBoxes
            },
            {
                id: 73,
                match_id: subModulesMatchIds[subModules.INVOICE],
                name: "Invoice",
                path: "/invoice",
                icon: FaFileInvoiceDollar
            },
            {
                id: 74,
                match_id: subModulesMatchIds[subModules.WHOLE_SALE_PAYMENT_RECEIVE],
                name: "Payment Receive",
                path: "/payment-receive",
                icon: FaMoneyBillWave
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <PrimarySalesOrderIcon isRouted={isRouted} />;
        },
    },
    {
        id: 11,
        match_id: moduleMatchIds[modules.SALE_RETURN],
        name: "Sales Return",
        path: "/sales-return",
        icon: FaBoxOpen,
        subModules: [
            {
                id: 201,
                match_id: subModulesMatchIds[subModules.SALE_RETURN],
                name: "Sales Return",
                path: "/",
                icon: FaUndoAlt,
            },
            {
                id: 202,
                match_id: subModulesMatchIds[subModules.MAKE_PAYMENT],
                name: "Make Payment",
                path: "/make-payment",
                icon: FaMoneyBillWave,
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <DirectSalesReturnIcon isRouted={isRouted} />;
        },
    },
    {
        id: 12,
        match_id: moduleMatchIds[modules.REPORTS],
        name: "Reports",
        path: "/reports",
        icon: FaFileAlt,
        subModules: [
            {
                id: 204,
                match_id: subModulesMatchIds[subModules.SALES_BY_PRODUCT],
                name: "Sales By Product",
                path: "/sales-by-product",
                icon: FaChartBar
            },
            {
                id: 205,
                match_id: subModulesMatchIds[subModules.SALES_BY_PRODUCT_CATEGORY],
                name: "Sales By Product Category",
                path: "/sales-by-product-category",
                icon: FaFileAlt
            },
            {
                id: 206,
                match_id: subModulesMatchIds[subModules.SALES_BY_CUSTOMER],
                name: "Sales By Customer",
                path: "/sales-by-customer",
                icon: FaUsers
            },
            {
                id: 207,
                match_id: subModulesMatchIds[subModules.SALES_DETAIL],
                name: "Sales Detail",
                path: "/sales-detail",
                icon: FaCashRegister,
            },
            {
                id: 208,
                match_id: subModulesMatchIds[subModules.PURCHASE_ORDER_BY_SUPPLIER],
                name: "Purchase Order By Supplier",
                path: "/purchase-order-by-supplier",
                icon: FaUserFriends
            },
            {
                id: 209,
                match_id: subModulesMatchIds[subModules.PURCHASE_ORDER_DETAIL],
                name: "Purchase Order Detail",
                path: "/purchase-order-detail",
                icon: FaCreditCard
            },
            {
                id: 300,
                match_id: subModulesMatchIds[subModules.STOCK_TRANSFER_REPORT],
                name: "Stock Transfer",
                path: "/stock-transfer",
                icon: FaTruck
            },
            {
                id: 301,
                match_id: subModulesMatchIds[subModules.STOCK_BALANCE_REPORT],
                name: "Stock Balance",
                path: "/stock-balance",
                icon: FaChartBar
            },
            {
                id: 302,
                match_id: subModulesMatchIds[subModules.STOCK_ADJUSTMENT_REPORT],
                name: "Stock Adjustment",
                path: "/stock-adjustment",
                icon: FaPencilAlt
            },
        ],
        renderIcon: (isRouted: boolean) => {
            return <DirectSalesReturnIcon isRouted={isRouted} />;
        },
    },
];

export type mainModuleType = typeof mainModules;

export default mainModules;
